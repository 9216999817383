<template>
  <form ref="profile_form" @submit.prevent="handleSubmit">
    <image-selector
      :defaultImage="reseller.logo"
      ressource_name="resellers"
      :ressource_id="reseller.id ? reseller.id : 0"
      field="logo"
      @imageChanged="
        (file_url) => {
          reseller.logo = file_url;
          onFormChanged();
        }
      "
    />
    <validation-error :errors="apiValidationErrors.attachment" />

    <base-input
      :label="`${$t('COMMON.NAME')} (*)`"
      :placeholder="$t('COMMON.NAME')"
      v-model="reseller.name"
      @change="
        () => {
          onFormChanged();
        }
      "
    />
    <validation-error :errors="apiValidationErrors.name" />

    <base-input
      :label="$t('COMMON.EXCERPT')"
      :placeholder="$t('COMMON.EXCERPT')"
    >
      <html-editor v-model="reseller.excerpt" @change="onFormChanged()">
      </html-editor>
    </base-input>
    <validation-error :errors="apiValidationErrors.excerpt" />

    <base-input
      :label="`${$t('COMMON.EMAIL')} (*)`"
      :placeholder="$t('COMMON.EMAIL')"
      v-model="reseller.email"
      @change="
        () => {
          onFormChanged();
        }
      "
    />
    <validation-error :errors="apiValidationErrors.email" />

    <!-- Phone number -->
    <div class="row">
      <div class="col-2">
        <base-input :label="`${$t('COMMON.TYPE')}`">
          <el-select
            :label="$t('COMMON.TYPE')"
            :placeholder="$t('COMMON.TYPE')"
            v-model="reseller.phone_type"
            @change="
              (type) => {
                reseller.phone_type = type;
                onFormChanged();
              }
            "
          >
            <el-option
              v-for="(value, key) in phoneTypesOptions"
              :key="key"
              :value="value"
              :label="$t(`COMMON.PHONE_TYPE_${value}`)"
            />
          </el-select>
        </base-input>
      </div>
      <div class="col-7">
        <base-input :label="`${$t('COMMON.PHONE')} (*)`">
          <phone-number-input
            :phoneNumber="reseller.phone"
            @phoneNumberChanged="
              (phone) => {
                reseller.phone = phone;
                onFormChanged();
              }
            "
            :required="true"
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.phone" />
      </div>
      <div class="col-3">
        <base-input
          :label="`${$t('COMMON.EXTENSION')}`"
          v-model="reseller.phone_extension"
          @change="
            () => {
              onFormChanged();
            }
          "
          :inputClasses="'extension-input'"
        />
        <validation-error :errors="apiValidationErrors.phone_extension" />
      </div>
    </div>

    <!-- Other phone numbers -->
    <base-input :label="`${$t('COMMON.OTHER_PHONES')}`">
      <phone-numbers-selector
        :phoneNumbers="reseller.other_phones"
        @phoneNumbersChanged="
          (phoneNumbers) => {
            reseller.other_phones = phoneNumbers;
            onFormChanged();
          }
        "
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.other_phones" />

    <base-input
      :label="`${$t('COMMON.ADDRESS')} (*)`"
      :placeholder="$t('COMMON.ADDRESS')"
      v-model="reseller.address"
      @change="
        () => {
          onFormChanged();
        }
      "
    />
    <validation-error :errors="apiValidationErrors.address" />

    <base-input
      :label="`${$t('COMMON.OWNER')} (*)`"
      :placeholder="$t('COMMON.OWNER')"
    >
      <user-selector
        :user="reseller.owner.id"
        :filterable="true"
        :showAll="false"
        :filterResellerNull="reseller.id ? false : true"
        :filterReseller="reseller.id ? reseller.id : null"
        :filterOrganizationNull="true"
        @userChanged="
          (userId) => {
            reseller.owner.id = userId;
            onFormChanged();
          }
        "
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.owner" />

    <h2 class="mt-5">{{ $t("RESELLERS.CONFIG_MANAGER") }}</h2>

    <base-input
      :label="`${$t('RESELLERS.CONFIG_MANAGER_APP_LOGO')}`"
      class="mt-2"
    >
      <image-selector
        :defaultImage="reseller.config_manager_app_logo"
        ressource_name="resellers"
        :ressource_id="reseller.id ? reseller.id : 0"
        field="manager_logo"
        @imageChanged="
          (file_url) => {
            reseller.config_manager_app_logo = file_url;
            onFormChanged();
          }
        "
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.config_manager_app_logo" />

    <base-input
      :label="`${$t('RESELLERS.CONFIG_MANAGER_APP_NAME')}`"
      :placeholder="$t('RESELLERS.CONFIG_MANAGER_APP_NAME')"
      v-model="reseller.config_manager_app_name"
      @change="
        () => {
          onFormChanged();
        }
      "
    />
    <validation-error :errors="apiValidationErrors.config_manager_name" />

    <base-input
      :label="`${$t('RESELLERS.CONFIG_MANAGER_URL_REGEX')}`"
      :placeholder="$t('RESELLERS.CONFIG_MANAGER_URL_REGEX')"
      v-model="reseller.config_manager_url_regex"
      @change="
        () => {
          onFormChanged();
        }
      "
    />
    <validation-error :errors="apiValidationErrors.config_manager_url_regex" />

    <div class="my-4">
      <base-button
        type="button"
        class="btn btn-sm btn-primary"
        native-type="submit"
        :disabled="loading"
      >
        <i class="fas fa-spinner fa-spin" v-if="loading" />
        {{
          reseller.id
            ? $t("RESELLERS.EDIT_RESELLER")
            : $t("RESELLERS.ADD_RESELLER")
        }}
      </base-button>
    </div>
  </form>
</template>
<script>
import { cloneDeep } from "lodash";
import { Select, Option } from "element-ui";
import { phoneTypesOptions } from "@/constants/common";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/form-mixin";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import UserSelector from "@/components/UserSelector.vue";
import ImageSelector from "@/components/ImageSelector.vue";
import PhoneNumberInput from "@/components/PhoneNumberInput.vue";
import PhoneNumbersSelector from "@/components/PhoneNumbersSelector.vue";

export default {
  layout: "DashboardLayout",

  components: {
    HtmlEditor,
    BaseInput,
    ValidationError,
    UserSelector,
    ImageSelector,
    PhoneNumberInput,
    PhoneNumbersSelector,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [formMixin],

  props: ["resellerData", "formErrors", "loading"],

  data() {
    return {
      reseller: { ...this.resellerData },
      phoneTypesOptions,
    };
  },

  created() {},

  methods: {
    async handleSubmit() {
      this.$emit("resellerSubmitted", this.reseller);
    },

    onFormChanged() {
      this.$emit("formChanged");
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    resellerData(resellerData) {
      if (resellerData) {
        this.reseller = {
          ...this.reseller,
          ...cloneDeep(resellerData),
        };
      }
    },
  },
};
</script>
