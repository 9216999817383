var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{ref:"profile_form",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('image-selector',{attrs:{"defaultImage":_vm.reseller.logo,"ressource_name":"resellers","ressource_id":_vm.reseller.id ? _vm.reseller.id : 0,"field":"logo"},on:{"imageChanged":(file_url) => {
        _vm.reseller.logo = file_url;
        _vm.onFormChanged();
      }}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.attachment}}),_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.NAME')} (*)`,"placeholder":_vm.$t('COMMON.NAME')},on:{"change":() => {
        _vm.onFormChanged();
      }},model:{value:(_vm.reseller.name),callback:function ($$v) {_vm.$set(_vm.reseller, "name", $$v)},expression:"reseller.name"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.name}}),_c('base-input',{attrs:{"label":_vm.$t('COMMON.EXCERPT'),"placeholder":_vm.$t('COMMON.EXCERPT')}},[_c('html-editor',{on:{"change":function($event){return _vm.onFormChanged()}},model:{value:(_vm.reseller.excerpt),callback:function ($$v) {_vm.$set(_vm.reseller, "excerpt", $$v)},expression:"reseller.excerpt"}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.excerpt}}),_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.EMAIL')} (*)`,"placeholder":_vm.$t('COMMON.EMAIL')},on:{"change":() => {
        _vm.onFormChanged();
      }},model:{value:(_vm.reseller.email),callback:function ($$v) {_vm.$set(_vm.reseller, "email", $$v)},expression:"reseller.email"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.email}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-2"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.TYPE')}`}},[_c('el-select',{attrs:{"label":_vm.$t('COMMON.TYPE'),"placeholder":_vm.$t('COMMON.TYPE')},on:{"change":(type) => {
              _vm.reseller.phone_type = type;
              _vm.onFormChanged();
            }},model:{value:(_vm.reseller.phone_type),callback:function ($$v) {_vm.$set(_vm.reseller, "phone_type", $$v)},expression:"reseller.phone_type"}},_vm._l((_vm.phoneTypesOptions),function(value,key){return _c('el-option',{key:key,attrs:{"value":value,"label":_vm.$t(`COMMON.PHONE_TYPE_${value}`)}})}),1)],1)],1),_c('div',{staticClass:"col-7"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.PHONE')} (*)`}},[_c('phone-number-input',{attrs:{"phoneNumber":_vm.reseller.phone,"required":true},on:{"phoneNumberChanged":(phone) => {
              _vm.reseller.phone = phone;
              _vm.onFormChanged();
            }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.phone}})],1),_c('div',{staticClass:"col-3"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.EXTENSION')}`,"inputClasses":'extension-input'},on:{"change":() => {
            _vm.onFormChanged();
          }},model:{value:(_vm.reseller.phone_extension),callback:function ($$v) {_vm.$set(_vm.reseller, "phone_extension", $$v)},expression:"reseller.phone_extension"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.phone_extension}})],1)]),_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.OTHER_PHONES')}`}},[_c('phone-numbers-selector',{attrs:{"phoneNumbers":_vm.reseller.other_phones},on:{"phoneNumbersChanged":(phoneNumbers) => {
          _vm.reseller.other_phones = phoneNumbers;
          _vm.onFormChanged();
        }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.other_phones}}),_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.ADDRESS')} (*)`,"placeholder":_vm.$t('COMMON.ADDRESS')},on:{"change":() => {
        _vm.onFormChanged();
      }},model:{value:(_vm.reseller.address),callback:function ($$v) {_vm.$set(_vm.reseller, "address", $$v)},expression:"reseller.address"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.address}}),_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.OWNER')} (*)`,"placeholder":_vm.$t('COMMON.OWNER')}},[_c('user-selector',{attrs:{"user":_vm.reseller.owner.id,"filterable":true,"showAll":false,"filterResellerNull":_vm.reseller.id ? false : true,"filterReseller":_vm.reseller.id ? _vm.reseller.id : null,"filterOrganizationNull":true},on:{"userChanged":(userId) => {
          _vm.reseller.owner.id = userId;
          _vm.onFormChanged();
        }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.owner}}),_c('h2',{staticClass:"mt-5"},[_vm._v(_vm._s(_vm.$t("RESELLERS.CONFIG_MANAGER")))]),_c('base-input',{staticClass:"mt-2",attrs:{"label":`${_vm.$t('RESELLERS.CONFIG_MANAGER_APP_LOGO')}`}},[_c('image-selector',{attrs:{"defaultImage":_vm.reseller.config_manager_app_logo,"ressource_name":"resellers","ressource_id":_vm.reseller.id ? _vm.reseller.id : 0,"field":"manager_logo"},on:{"imageChanged":(file_url) => {
          _vm.reseller.config_manager_app_logo = file_url;
          _vm.onFormChanged();
        }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.config_manager_app_logo}}),_c('base-input',{attrs:{"label":`${_vm.$t('RESELLERS.CONFIG_MANAGER_APP_NAME')}`,"placeholder":_vm.$t('RESELLERS.CONFIG_MANAGER_APP_NAME')},on:{"change":() => {
        _vm.onFormChanged();
      }},model:{value:(_vm.reseller.config_manager_app_name),callback:function ($$v) {_vm.$set(_vm.reseller, "config_manager_app_name", $$v)},expression:"reseller.config_manager_app_name"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.config_manager_name}}),_c('base-input',{attrs:{"label":`${_vm.$t('RESELLERS.CONFIG_MANAGER_URL_REGEX')}`,"placeholder":_vm.$t('RESELLERS.CONFIG_MANAGER_URL_REGEX')},on:{"change":() => {
        _vm.onFormChanged();
      }},model:{value:(_vm.reseller.config_manager_url_regex),callback:function ($$v) {_vm.$set(_vm.reseller, "config_manager_url_regex", $$v)},expression:"reseller.config_manager_url_regex"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.config_manager_url_regex}}),_c('div',{staticClass:"my-4"},[_c('base-button',{staticClass:"btn btn-sm btn-primary",attrs:{"type":"button","native-type":"submit","disabled":_vm.loading}},[(_vm.loading)?_c('i',{staticClass:"fas fa-spinner fa-spin"}):_vm._e(),_vm._v(" "+_vm._s(_vm.reseller.id ? _vm.$t("RESELLERS.EDIT_RESELLER") : _vm.$t("RESELLERS.ADD_RESELLER"))+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }